<template>
    <div>
        <pretty-select
            v-if="options"
            :placeholder="placeholder"
            label="artworkConfigurationFileName"
            :options="options"
            :value="value"
            :reduce="file => file.artworkConfigurationFilePath"
            @input="emit"
        />
    </div>
</template>
<script>
import PrettySelect from '../utils/PrettySelect'
export default {
    components: { PrettySelect },
    props: ['parameters', 'value', 'placeholder'],
    methods: {
        emit (value) {
            this.$emit('input', value)
        },
    },
    computed: {
        options () {
            if (this.$store.state.products.artworkFiles === null || this.$store.state.products.artworkFiles.length === 0) {
                return null
            }
            if (!this.parameters) {
                return this.$store.state.products.artworkFiles
            }

            const parameterEntries = Object.entries(this.parameters)

            return this.$store.state.products.artworkFiles.filter(file => {
                return parameterEntries.every(([key, value]) => {
                    return file[key] && file[key] === value
                })
            })
        },
    },
}
</script>
