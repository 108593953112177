<template>
    <div v-if="availableAttributes">
        <mercur-card class="templateset mx-4 mb-3" ref="templateset">
            <div class="row">
                <div class="col-6" v-if="showFiles">
                    <div>
                        <h4 class="text-secondary">Files</h4>
                        <template v-if="files.length">
                            <div v-for="(file, key) in files" :key="key">
                                <i class="fas fa-file-alt"></i>
                                <div>
                                    <span :title="file.templateName">{{file.templateName}}</span>
                                    <span :title="file.originalFilename || file.originalFileName">{{file.originalFilename}}{{file.originalFileName}}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            No files yet. Please <a href="#" @click="editing = true">upload</a> them.
                        </template>
                    </div>
                </div>
                <div class="col-8" v-if="showConfiguration">
                    <strong>{{configuration ? configuration.internalName : ''}}</strong>
                    <template v-if="configuration">
                        <table class="configuration-table">
                            <tr v-for="(configValue, configKey) in configuration" :key="configKey">
                                <td>{{configKey | beautify}}</td>
                                <td :title="configValue">{{configValue}}</td>
                            </tr>
                        </table>
                    </template>
                    <template v-else>none</template>
                </div>
                <div class="col-4">
                    <div v-if="attributes">
                        <h4 class="text-secondary">Attributes</h4>
                        <div v-if="allAttributeOptionsValue">All</div>
                        <template v-for="(selectedOptions, attributeKey) in attributes">
                            <div class="flex align-items-center my-4" v-if="selectedOptions.length" :key="attributeKey">
                                <i class="fas fa-list-ul"></i>
                                <div class="flex flex-column ml-4">
                                    <span>{{attributeKey | beautify}}</span>
                                    <span class="text-secondary" :title="selectedOptions.join('\n')">{{selectedOptions.join(', ')}}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="text-right">
                <mercur-button class="btn btn-icon" @click="deleteConfirmationDialogActive = true">
                    <i class="fas fa-trash"></i>
                </mercur-button>
                <mercur-button class="btn btn-icon btn-yellow" @click="editing = true">
                    <i class="fas fa-pen"></i>
                </mercur-button>
                <mercur-dialog :is-open.sync="deleteConfirmationDialogActive">
                    <div slot="header"><h3>Are you sure you want to delete this template set?</h3></div>
                    <div slot="default">Once you save the page, it is not possible to recover these templates.</div>
                    <div slot="footer">
                        <mercur-button class="btn btn-raised text-uppercase" @click="deleteConfirmationDialogActive = false">
                            Cancel
                        </mercur-button>
                        <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="$emit('delete', {index, artworkConfigurationId: artworkConfigurationId})">
                            Delete
                        </mercur-button>
                    </div>
                </mercur-dialog>
            </div>
        </mercur-card>

        <mercur-dialog :is-open.sync="editing" width="80%">
            <h2 class="font-weight-normal">Edit templates</h2>

            <div slot="default">
                <mercur-tabs v-if="editing && workingCopies" :activeTab="activeTab">
                    <mercur-tab title="Attributes" id="attributes" class="p-3">
                        <mercur-checkbox class="my-3" v-model="allAttributeOptions">All attributeOptions</mercur-checkbox>
                        <div v-if="!allAttributeOptions" class="attribute-list row">
                            <div v-for="(attributeOptions, attributeKey) in availableAttributes" :key="attributeKey" class="attribute-block col-2">
                                <attribute-block :bordered="false" :scrollable="true">
                                    <template slot="header">
                                        <h3 class="font-weight-normal">{{attributeKey | beautify}}</h3>
                                    </template>
                                    <div v-for="(option, key) in attributeOptions" :key="key" class="p-3">
                                        <mercur-checkbox v-model="workingCopies.attributes[attributeKey]" :value="option.option">{{option.option}}</mercur-checkbox>
                                    </div>
                                </attribute-block>
                            </div>
                        </div>
                        <div v-else>All attribute options</div>
                    </mercur-tab>

                    <mercur-tab title="Specifications" id="config" v-if="showConfiguration">
                        <p>Please add the configuration that applies to the attribute options that were selected on the previous screen</p>
                        <mercur-checkbox v-model="workingCopies.stockItem">Is stock item</mercur-checkbox>
                        <div class="configuration-fields">
                            <div v-for="field in configurationFields" class="configuration-field" :class="`configuration-field--${field.section}`" :key="field.value">
                                <template v-if="field.type === 'checkbox'">
                                    <mercur-checkbox class="my-3" v-model="workingCopies.configuration[field.value]">{{field.title}}</mercur-checkbox>
                                </template>
                                <template v-else-if="field.type === 'file'">
                                    <artwork-file-selector
                                        v-model="workingCopies.configuration[field.value]"
                                        :parameters="field.filterParams"
                                        :placeholder="field.title"
                                    ></artwork-file-selector>
                                </template>
                                <template v-else>
                                    <mercur-select v-if="field.type === 'select'" v-model="workingCopies.configuration[field.value]">
                                        <template slot="label">{{field.title}}</template>
                                        <option
                                            v-for="option in field.options"
                                            :key="option.value"
                                            :value="option.value"
                                        >
                                            {{option.title}}
                                        </option>
                                    </mercur-select>
                                    <mercur-input v-else-if="field.type === 'number'" v-model.number="workingCopies.configuration[field.value]" type="number">
                                        <span slot="suffix" v-if="field.suffix">{{field.suffix}}</span>
                                    </mercur-input>
                                    <mercur-input v-else v-model="workingCopies.configuration[field.value]">
                                        <span slot="suffix" v-if="field.suffix">{{field.suffix}}</span>
                                    </mercur-input>
                                </template>
                            </div>
                        </div>
                    </mercur-tab>

                    <mercur-tab title="Files" id="files" v-if="showFiles">
                        <div class="row">
                            <div class="col-6">
                                <div class="text-center text-secondary">Upload new file</div>
                                <filedropper
                                    class="filedrop-area"
                                    @uploadUpdate="uploadUpdate"
                                    :url="uploadUrl"
                                    button-text="select">
                                    &nbsp;
                                </filedropper>
                                <div class="text-center text-secondary">Select previously uploaded file</div>
                                <ag-grid-vue
                                    class="ag-grid existing-template-list ag-theme-material border"
                                    :columnDefs="columnDefs"
                                    :animateRows="true"
                                    rowModelType="clientSide"
                                    :rowData="filteredAvailableTemplateFiles"
                                    :pagination="true"
                                    :enableRangeSelection="false"
                                    :suppressCellSelection="true"
                                    :suppressRowClickSelection="true"
                                    rowSelection="multiple"
                                    @row-selected="onRowSelected"
                                ></ag-grid-vue>
                            </div>

                            <div class="col-6">
                                <div class="text-center text-secondary">Selected files</div>
                                <div class="files">
                                    <template v-if="workingCopies.files.length">
                                        <div v-for="(file, key) in workingCopies.files" :key="key" class="list-file flex align-items-center justify-content-between" :class="listFileClasses(file)">
                                            <div>
                                                <span class="list-file__filename">{{file.templateName}} ({{file.originalFilename}}{{file.originalFileName}})</span>
                                                <span v-show="file.status">Status: {{file.status | beautify}}</span>
                                                <mercur-progress-bar class="list-file__progress" v-if="file.status === 'pending' || file.status === 'completed'"></mercur-progress-bar>
                                            </div>

                                            <mercur-button class="btn btn-icon" @click="workingCopies.files.splice(key, 1)">&times;</mercur-button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        No template files
                                    </template>
                                </div>
                            </div>
                        </div>
                    </mercur-tab>
                </mercur-tabs>
            </div>

            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="editing = false">Discard changes</mercur-button>
                <mercur-button class="btn btn-raised btn-yellow text-uppercase" @click="nextStep">Continue</mercur-button>
            </div>
        </mercur-dialog>
    </div>
</template>

<script>
import AttributeBlock from '@/components/AttributeBlock'
import collect from 'collect.js'
import Filedropper from '@/components/utils/Filedropper'
import { AgGridVue } from 'ag-grid-vue'
import 'ag-grid-enterprise'
import CONFIG from '@root/config'
import ArtworkFileSelector from '../products/ArtworkFileSelector'

export default {
    name: 'ProductTemplateSet',
    props: [
        'attributes',
        'files',
        'index',
        'configuration',
        'stockItem',
        'allAttributeOptionsValue',
        'editOnCreate',
        'availableAttributes',
        'availableTemplateFiles',
        'templateSection',
        'artworkConfigurationId',
    ],
    components: { ArtworkFileSelector, AttributeBlock, Filedropper, AgGridVue },
    data () {
        return {
            deleteConfirmationDialogActive: false,
            editing: null,
            workingCopies: null,
            activeTab: 'attributes',
            defaultColDef: null,
            columnDefs: null,
            configurationFields: CONFIG.ARTWORK_CONFIGURATION.FIELDS,
        }
    },

    computed: {
        filteredAvailableTemplateFiles () {
            if (this.availableTemplateFiles === null) {
                return null
            }

            return this.availableTemplateFiles.filter(file => {
                return !this.workingCopies.files.find(selectedFile => {
                    return selectedFile.templateId === file.templateId
                })
            })
        },
        allAttributeOptions: {
            get () {
                return !!this.workingCopies.allAttributeOptions
            },
            set (value) {
                this.$set(this.workingCopies, 'allAttributeOptions', value)

                if (value) {
                    this.$nextTick(() => {
                        this.$set(this.workingCopies, 'attributes', null)
                    })
                } else {
                    this.$set(this.workingCopies, 'attributes', Object.keys(this.availableAttributes).reduce((attributes, attributeKey) => {
                        attributes[attributeKey] = []
                        return attributes
                    }, {}))
                }
            },
        },
        uploadUrl () {
            return CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES.UPLOAD_BULK
                .replace('{supplierId', this.supplierId)
                .replace('{templateSection}', this.$route.params.templateSection)
        },
        showConfiguration () {
            return this.templateSection && this.templateSection.value === 'artwork'
        },
        showFiles () {
            return this.templateSection && this.templateSection.value === 'templates'
        },
        mergedConfig () {
            return this.configurationFields.reduce((total, item) => {
                if (this.workingCopies.configuration.hasOwnProperty(item.value)) {
                    total[item.value] = this.workingCopies.configuration[item.value]
                } else {
                    total[item.value] = item.type === 'checkbox' ? false : ''
                }

                return total
            }, {})
        },
    },

    methods: {
        nextStep () {
            if (this.activeTab === 'attributes') {
                if (this.showConfiguration) {
                    this.activeTab = 'config'
                    return
                }
                this.activeTab = 'files'

                return
            }

            if (this.activeTab === 'config' && this.showFiles) {
                this.activeTab = 'files'
                return
            }

            return this.save()
        },

        save () {
            const savedData = JSON.parse(JSON.stringify(this.workingCopies))
            savedData.index = this.index
            savedData.files = savedData.files.map(file => {
                delete file.status
                file.originalFilename = file.originalFilename = file.originalFileName || file.originalFilename

                return file
            })

            savedData.configuration = JSON.parse(JSON.stringify(this.mergedConfig))

            this.$emit('save', savedData)
            this.editing = false

            const templateSetClasslist = this.$refs.templateset.$el.classList
            templateSetClasslist.add('templateset--is-updated')
            setTimeout(() => {
                templateSetClasslist.remove('templateset--is-updated')
            }, 100)
        },

        uploadUpdate ($event) {
            const file = $event.file
            if ($event.type === 'start') {
                this.workingCopies.files.unshift({
                    ...file,
                    status: 'pending',
                    percentCompleted: 0,
                })

                return
            }

            const listFile = this.workingCopies.files.find(workingFile => file.objectName === workingFile.objectName)

            if ($event.type === 'completed') {
                this.$set(listFile, 'status', 'completed')
                setTimeout(() => {
                    this.$set(listFile, 'status', null)
                }, 3000)
                return
            }

            if ($event.type === 'progress') {
                this.$set(listFile, 'percentCompleted', $event.percentCompleted)
            }
        },

        listFileClasses (file) {
            let classes = []
            if (file.status === 'pending' || file.status === 'completed') {
                classes.push(`is-${file.status}`)
            }

            return classes.map(modifier => `list-file--${modifier}`)
        },

        onRowSelected (params) {
            const data = params.data
            const node = params.node
            if (node.selected) {
                this.workingCopies.files.unshift({
                    ...data,
                    status: null,
                })

                return
            }

            const fileIndex = this.workingCopies.files.findIndex(file => file.templateId === data.templateId)
            this.workingCopies.files.splice(fileIndex, 1)
        },
    },
    watch: {
        editing (editing) {
            if (!editing) {
                return
            }

            this.activeTab = 'attributes'

            const currentAttributes = JSON.parse(JSON.stringify(this.attributes))
            const workingAttributes = collect(Object.entries(this.availableAttributes)).mapWithKeys(attribute => {
                const currentValue = currentAttributes && currentAttributes[attribute[0]] ? currentAttributes[attribute[0]] : null
                return [attribute[0], (currentValue || [])]
            }).all()

            this.workingCopies = Object.assign({}, {
                index: this.index,
                attributes: workingAttributes,
                files: this.files ? JSON.parse(JSON.stringify(this.files)) : null,
                configuration: this.configuration,
                stockItem: this.stockItem,
                allAttributeOptions: this.allAttributeOptionsValue,
            })
        },
        editOnCreate (value) {
            if (value) {
                this.editing = true
            }
        },
    },
    beforeMount () {
        this.defaultColDef = {
            resizable: true,
        }
        this.columnDefs = [
            {
                checkboxSelection: true,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerName: 'Name',
                field: 'templateName',
                sortable: true,
            },
            {
                headerName: 'Filename',
                field: 'originalFileName',
                sortable: true,
            },
            {
                headerName: 'Updated',
                field: 'dateUpdated',
                sortable: true,
                valueFormatter: ({ value }) => {
                    return this.$options.filters.asDatetime(value)
                },
            },
        ]
    },
    created () {
        if (this.editOnCreate) {
            this.editing = true
        }
    },
}
</script>

<style scoped lang="scss">
    $dialog-width: 1124px;
    .files {
        max-height: 450px;
        overflow-x: hidden;
        overflow-y: auto;
        max-width: $dialog-width;
        position: relative;
    }
    .existing-template-list {
        max-height: 380px;
        height: 380px;
    }
    .edit-templateset {
        max-width: 100%;
        width: $dialog-width;
        min-height: 400px;
        height: 90%;
        max-height: 90%;
        top: 5%;
    }

    .templateset {
        transition: background-color 1.5s ease-out;

        &--is-updated {
            background-color: #fbe38f;
            transition: background-color 10ms;
        }
    }

    .attribute-list {
        z-index: 30;
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-bottom: 10px;
    }

    .attribute-block {
        padding-left: 5px;
        padding-right: 5px;
    }

    .list-file {
        margin-bottom: 2px;

        &--is-pending {
            background-color: #fff2c3;
        }

        &--is-completed {
            background-color: #e8ffe5;
            transition: background-color 3s;
        }
        &__filename {
            line-height: 1.6em;
            font-weight: 500;
        }
        &__progress {
            margin-top: 7px;
            margin-bottom: 3px;
        }
    }

    fieldset {
        border: 1px solid #aaa;
        background-color: #eee;
    }

    legend {
        font-weight: bold;
        background-color: #eee;
        border-radius: 3px;
        padding: 5px;
    }

    .speed-dial {
        position: absolute;
        bottom: 13px;
        z-index: 4;

        &__target {
            margin-right: 10px;
        }

        &__content {
            right: 5px;
        }
    }

    .filedrop-area {
        margin-top: 10px;
        border-bottom: 1px solid #dedede;
        padding-bottom: 40px;
        margin-bottom: 30px;
    }
    .configuration-fields {
        column-count: 2;
        column-gap: 20px;
    }
    .configuration-field {
        break-inside: avoid;
        padding-bottom: 2px;
    }
    .configuration-table {
        td {
            font-size: 12px;
            padding-right: 2px;
            line-height: 1.1em;
        }
    }

</style>
