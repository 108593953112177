<template>
    <component
        :class="{'attribute-block--is-disabled': disabled, 'transparent': transparentBackground, 'p-0': 'transparentBackground'}"
        :is="transparentBackground ? 'div' : 'mercur-card'">
        <div :class="{'primary': type === 'primary', 'disabled': disabled}">
            <div class="p-3">
                <slot name="header">
                </slot>
            </div>
        </div>
        <div :class="{ bordered, scrollable }">
            <slot></slot>
        </div>
    </component>
</template>

<script>
export default {
    name: 'AttributeBlock',
    props: {
        bordered: {
            default: true,
        },
        type: {
            default: 'primary',
        },
        scrollable: {
            default: false,
        },
        disabled: {
            default: false,
        },
        transparentBackground: {
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
    .primary {
        background-color: #000;
        color: #fff;
    }
    .scrollable {
        flex: 1 0 100%;
        max-height: 385px;
        overflow: auto;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .bordered {
        border: 1px solid rgba(#000, .12);
    }
    .disabled {
        opacity: 0.4;
    }
    .transparent {
        background-color: transparent;

    }

    .attribute-block--is-disabled {
        pointer-events: none;
    }
</style>
