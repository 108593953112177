<template>
    <div class="product-templates-view h-100">
        <div class="flex flex-column justify-content-between h-100">
            <div>
                <div class="bg-yellow px-4 py-2">
                    <div class="flex align-items-center justify-content-between">
                        <h2 class="font-weight-normal">{{templateSection.title}}</h2>
                        <mercur-button class="btn btn-icon" @click="addNewTemplateSet">
                            <i class="fas fa-plus"></i>
                        </mercur-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" v-if="productConfiguration">
                        <div v-for="(templateSet, key) in configurationSets" :key="key">
                            <product-template-set
                                @save="saveAttributeSet"
                                @delete="deleteAttributeSet"
                                :attributes="templateSet.attributes"
                                :artwork-configuration-id="templateSet.artworkConfigurationId"
                                :available-attributes="productConfiguration.attributeConfiguration.selectedAttributes"
                                :files="templateSet.files"
                                :configuration="templateSet.configuration"
                                :all-attribute-options-value="templateSet.allAttributeOptions"
                                :index="key"
                                :stock-item="templateSet.stockItem"
                                :edit-on-create="templateSet.editOnCreate"
                                :available-template-files="availableTemplateFiles"
                                :template-section="templateSection"
                            ></product-template-set>
                        </div>
                        <mercur-card v-if="productConfiguration && configurationSets && configurationSets.length === 0">
                            No templates are set for this product
                        </mercur-card>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <mercur-button v-if="!parentProductConfiguration" class="btn btn-icon" :disabled="!isDirty || isSubmitting" @click="save">
                    <transition name="actionbutton">
                        <i v-if="!isSubmitting" class="fas fa-save submit"></i>
                        <i v-if="isSubmitting" class="fas fa-spinner pending"></i>
                    </transition>
                </mercur-button>
            </div>
        </div>
    </div>
</template>
<script>
import ProductTemplateSet from '@/components/templates/ProductTemplateSet'
import CONFIG from '@root/config'

export default {
    name: 'ProductTemplatesView',

    components: {
        ProductTemplateSet,
    },
    props: {
        parentProductConfiguration: {
            default: null,
        },
    },
    data: function () {
        return {
            initialTemplates: null,
            templates: null,
            isSubmitting: false,
            isDirty: false,
            availableTemplateFiles: null,
            pendingRemovalArtworkConfigurationIds: [],
            breadcrumbElement: {
                name: 'Templates',
            },
        }
    },
    computed: {
        productConfiguration () {
            const supplierId = this.supplierId
            const productConfigurationId = this.$route.params.productConfigurationId

            if (!productConfigurationId && this.parentProductConfiguration) {
                return this.parentProductConfiguration
            }

            const productConfiguration = this.$store.state.products.products[`${supplierId}_${productConfigurationId}`]

            if (productConfiguration) {
                return productConfiguration
            }

            return null
        },

        templateSection () {
            let templateSection = this.$route.params.templateSection
            if (!templateSection) {
                templateSection = 'artwork'
            }
            return CONFIG.PRODUCT_TEMPLATE_SECTIONS.find(section => section.value === templateSection)
        },

        configurationSets () {
            if (!this.productConfiguration) {
                return null
            }

            if (this.templateSection.value === 'artwork') {
                return this.productConfiguration.artworkConfiguration
            }
            return this.productConfiguration.templateConfiguration
        },

        backLink () {
            return {
                name: 'SupplierProductView',
                params: {
                    productConfigurationId: this.$route.params.productConfigurationId,
                },
            }
        },
    },

    methods: {
        saveAttributeSet (eventData) {
            this.configurationSets.splice(eventData.index, 1, {
                allAttributeOptions: eventData.allAttributeOptions,
                attributes: eventData.attributes,
                configuration: eventData.configuration,
                files: eventData.files,
                stockItem: eventData.stockItem,
            })
            this.isDirty = true

            this.$root.$emit('notification:global', {
                message: 'Templates were set. Please save your changes.',
                actions: [
                    {
                        text: 'Save changes',
                        action: () => {
                            this.saveTemplates()
                        },
                    },
                ],
            })

            if (this.parentProductConfiguration) {
                this.$forceUpdate()
            }
        },
        deleteAttributeSet (eventData) {
            if (eventData.artworkConfigurationId) {
                this.pendingRemovalArtworkConfigurationIds.push(eventData.artworkConfigurationId)
            }
            this.configurationSets.splice(eventData.index, 1)
            this.isDirty = true
        },
        addNewTemplateSet () {
            this.configurationSets.unshift({
                allAttributeOptions: true,
                attributes: null,
                configuration: {},
                files: [],
                stockItem: false,
                editOnCreate: true,
            })

            if (this.parentProductConfiguration) {
                this.$forceUpdate()
            }
        },
        save () {
            if (this.$route.params.templateSection === 'artwork') {
                this.saveArtworkConfigurations()
            } else {
                this.saveTemplates()
            }
        },
        saveTemplates () {
            const supplierId = this.supplierId
            const productConfigurationId = this.$route.params.productConfigurationId

            this.isSubmitting = true

            const payload = {
                templates: this.configurationSets.map(templateSet => {
                    return templateSet
                }),
                productHash: this.productConfiguration.productHash,
            }
            const url = CONFIG.API.ROUTES.PRODUCTS.TEMPLATES.UPDATE
                .replace('{supplierId}', supplierId)
                .replace('{productConfigurationId}', productConfigurationId)
                .replace('{templateSection}', this.$route.params.templateSection)

            this.$api.post(url, payload).then(result => {
                this.$root.$emit('notification:global', {
                    message: `Templates for ${this.productConfiguration.productConfigurationName} successfully saved.`,
                })
            }).catch(error => {
                console.error(error)
                this.$root.$emit('notification:global', {
                    message: `Saving templates failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.isSubmitting = false
            })
        },
        saveArtworkConfigurations () {
            const supplierId = this.supplierId
            const productConfigurationId = this.$route.params.productConfigurationId

            const payload = {
                artworkConfigurations: this.configurationSets.map(templateSet => {
                    return templateSet
                }),
                productHash: this.productConfiguration.productHash,
                removeArtworkConfigurationIds: this.pendingRemovalArtworkConfigurationIds,
            }

            this.isSubmitting = true
            const url = CONFIG.API.ROUTES.PRODUCTS.TEMPLATES.UPDATEARTWORKCONFIGURATION
                .replace('{supplierId}', supplierId)
                .replace('{productConfigurationId}', productConfigurationId)

            this.$api.post(url, payload).then(result => {
                this.$root.$emit('notification:global', {
                    message: `Artwork configuration for '${this.productConfiguration.productConfigurationName}' successfully saved.`,
                })
                this.pendingRemovalArtworkConfigurationIds = []
            }).catch(error => {
                console.error(error)
                this.$root.$emit('notification:global', {
                    message: `Saving artwork configuration failed. Please try again.`,
                    type: 'error',
                })
            }).finally(() => {
                this.isSubmitting = false
            })
        },
    },

    watch: {
        'productConfiguration.templateConfiguration' (templates) {
            this.initialTemplates = JSON.parse(JSON.stringify(templates))
        },
        'productConfiguration.artworkConfiguration' (artworkConfigurations) {
            this.initialTemplates = JSON.parse(JSON.stringify(artworkConfigurations))
        },
        templateSection: {
            handler (section) {
                if (!section) {
                    return
                }

                this.breadcrumbElement.name = section.title
                return section
            },
            deep: true,
            immediate: true,
        },
    },

    created () {
        if (this.$route.params.templateSection === 'supplier') {
            const templatesOverviewUrl = CONFIG.API.ROUTES.SUPPLIERS.TEMPLATES.OVERVIEW
                .replace('{supplierId}', this.supplierId)
                .replace('{templateSection}', this.$route.params.templateSection)
            this.addJob(templatesOverviewUrl)
            this.$api.post(templatesOverviewUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
                this.availableTemplateFiles = data.data.items
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting template failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(templatesOverviewUrl)
            })
        }
    },
}
</script>

<style scoped lang="scss">
    .product-templates-view {
        padding-bottom: 70px;
        max-width: 900px;
        padding-left: 7px;
        padding-right: 7px;
    }

    .actionbutton-enter-active, .actionbutton-leave-active {
        transition: opacity 0.5s ease, transform .5s ease-in-out;

        &.submit {
            transition: opacity 0.5s ease, transform 0s ease-in-out;
        }
    }

    .actionbutton-enter, .actionbutton-leave-to {
        opacity: 0;
        position: absolute;
        transform: rotate(360deg);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }

    .pending {
        animation: spin 1s infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0)
        }
        100% {
            transform: rotate(180deg)
        }
    }
</style>
